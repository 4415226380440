<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <TemplateSelect />
            <ValidationObserver v-slot="{ valid }">
              <Component
                v-if="form"
                :is="form"
                ref="formRef"
                :key="$route.fullPath"
              ></Component>
              <Controls
                :valid="valid"
                :formRef="$refs.formRef"
              />
            </ValidationObserver>
          </v-col>
          <v-col md="4">
            <Meta />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import TemplateSelect from '@/components/item/TemplateSelect'
import Meta from '@/components/item/Meta'
import Controls from "@/components/item/Controls.vue";

export default {
  components: {
    TemplateSelect,
    Meta,
    Controls,
  },
  computed: {
    ...mapState('item', [
      'form',
    ]),
  },
  beforeMount() {
    // Creating a new item
    // The router path is /items/new/:templateIndex
    if (this.$route.params.templateIndex) {
      this.$store.commit('item/setUuid', "") // Set to empty string to indicate a new item
      this.$store.commit('item/setUpdated', new Date())
      this.$store.commit('item/setStatus', 'Draft')

      // @note this.$router is not available in the vuex store, so the template index is set here.
      this.$store.commit('item/setPresetIndex', this.$route.params.templateIndex)
      this.$store.dispatch('item/loadPresetValues')
    }

    // Updating an existing item
    // The router path is /items/update/:uuid
    if (this.$route.params.uuid) {
      this.$store.commit('item/setUuid', this.$route.params.uuid)
      this.$store.dispatch('item/loadApiValues')
    }
  },
}
</script>