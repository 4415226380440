<template>
  <div v-if="showWordCount" class="word-count my-4">
    <label>Advised word count: <b>{{ advisedWordCount }}</b></label>
    <svg v-if="showGoldilocks" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.8446 0.448486C3.77855 0.448486 0.478577 3.62182 0.478577 7.53182C0.478577 11.4418 3.77855 14.6152 7.8446 14.6152C11.9106 14.6152 15.2106 11.4418 15.2106 7.53182C15.2106 3.62182 11.9106 0.448486 7.8446 0.448486ZM6.37139 11.0735L2.68838 7.53182L3.72699 6.53307L6.37139 9.0689L11.9622 3.69265L13.0008 4.69849L6.37139 11.0735Z" fill="#9BDD84"/>
    </svg>
    <label class="push-right">(Current: {{ currentWordCount }})</label>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "item-cms-word-count",
  computed: {
    ...mapState('item', [
      'lengthMin',
      'lengthMax',
      'currentWordCount',
    ]),
    showWordCount: function() {
      return this.lengthMin && this.lengthMax
    },
    // The advised word count is in the middle of the minimum and the maximum.
    advisedWordCount: function() {
      return this.lengthMin + Math.floor((this.lengthMax - this.lengthMin) / 2)
    },
    // Show the green tick if the word count is in the goldilocks zone
    showGoldilocks: function() {
      return (
        this.currentWordCount >= this.lengthMin &&
        this.currentWordCount <= this.lengthMax
      );
    }
  },
}
</script>

<style lang="scss" scoped>
  .word-count {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    .push-right {
      margin-left: auto;
    }
    svg {
      margin: 1px 4px;
      height: 14pt;
      width: 14pt;
    }
  }
</style>