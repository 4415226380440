<template>
  <v-row v-if="showTemplateSelect" align="center" class="mb-2">
    <v-col md="auto"> Select a template: </v-col>
    <v-col md="auto">
      <v-select
        :items="templates"
        v-model="selected"
        return-object
        hide-details
        filled
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('item', [
      'isUpdate',
      'template',
      'templates',
    ]),
    showTemplateSelect () {
      return !this.isUpdate && this.templates.length > 1
    },
    selected: {
      get() { return this.template },
      set(template) {
        this.$store.commit('item/setTemplate', template)
        this.$store.commit('item/setWordCount', '')
        this.$store.dispatch('item/setForm')
      },
    },
  },
}
</script>