<template>
  <div v-if="form">
    <section class="user-feedback my-4">
      <v-alert dense outlined text v-if="success" type="success">
        {{ success }}
      </v-alert>
      <v-alert dense outlined text v-if="error" type="warning">
        {{ error }}
      </v-alert>
      <div v-if="isBusy" class="dot-windmill"></div>
    </section>
    <section class="my-8">
      <Button
        :title="!draft && 'Save is only available for Status: Draft.'"
        type="ghost"
        :disabled="isBusy || invalid || !draft || audioIsInvalid || isMissingRequiredInput"
        @click="save">Save</Button>
      <Button
        v-if="isUpdate"
        type="ghost"
        :disabled="isBusy"
        @click="() => this.$store.dispatch('item/toggleStatus')">{{ toggleStatusLabel }}</Button>
      <Button
        v-if="isUpdate"
        type="ghost"
        :disabled="isBusy || discarded"
        @click="() => this.$store.dispatch('item/discard')">Discard</Button>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@/components/item/Button'

export default {
  props: [
    'valid', // @see: https://forum.vuejs.org/t/watch-slot-props/63228/5
    'formRef',
  ],
  components: {
    Button,
  },
  computed: {
    ...mapState('item', [
      'uuid',
      'form',
      'status',
      'isBusy',
      'isUpdate',
      'success',
      'error',
      'audioIsInvalid',
      'isMissingRequiredInput',
    ]),
    invalid: ({ valid }) => !valid,
    draft: ({ status }) => status === 'Draft',
    deactivated: ({ status }) => status === 'Deactivated',
    discarded: ({ status }) => status === 'Discarded',
    toggleStatusLabel: ({ status }) => status === 'Live' ? 'Deactivate' : 'Activate',
  },
  methods: {
    save() {
      // Calling onSubmit() here invites the item template to populate this.payload
      // @todo figure out a better way to do this
      this.formRef.onSubmit()
      this.$store.dispatch('item/save')
    },
  },
  mounted() {
    this.$store.commit('item/setSuccess', '')
    this.$store.commit('item/setError', '')
  },
  watch: {
    // Reroute when an item is created. It's detected by the uuid being set in the item store.
    uuid(newValue, oldValue) {
      if (newValue === oldValue) return
      if (this.$route.params.uuid === newValue) return
      this.$router.replace(`/items/update/${newValue}`)
      this.$store.commit('item/setUuid', newValue)
      this.$store.dispatch('item/loadApiValues')
    },
  }
}
</script>

<style lang="scss" scoped>
button {
  width: 100%;
  max-width: 120pt;
  margin-right: 8px;
  margin-bottom: 8px;
}
.user-feedback {
  height: 20px;
  .dot-windmill {
    top: 5px;
    left: 25px;
  }
}
</style>