<template>
  <v-card color="var(--default-grey)" elevation="0" class="pa-4">
    <div class="mb-2">
      <v-avatar color="secondary white--text" size="38">{{ userInitials }}</v-avatar>
      <strong class="mx-2">{{ userName }}</strong>
    </div>
    <section>
      <v-chip class="mb-2" label text-color="var(--text-color)">
        <v-icon left>mdi-calendar</v-icon>
        {{ updated | dateString }}
      </v-chip>
    </section>
    <section class="mb-2">
      <v-chip dark class="mr-1 mb-1" color="var(--dark-orangey-red)">Template: <strong>{{ template.text }}</strong></v-chip>
      <v-chip dark class="mr-1 mb-1" color="var(--dark-light-blue)">Level: <strong>{{ level }}</strong></v-chip>
      <v-chip dark class="mr-1 mb-1" color="var(--dark-grey)">Bucket: <strong>{{ bucket }}</strong></v-chip>
      <v-chip dark class="mr-1 mb-1" color="var(--purple-blue)">Skill: <strong>{{ skill }}</strong></v-chip>
      <v-chip dark class="mr-1 mb-1" color="var(--grey)">Status: <strong>{{ status }}</strong></v-chip>
    </section>
    <section v-if="uuid">
      <v-chip class="mb-2"
        label
        text-color="var(--text-color)"
         @click="copyToClipboard(uuid)">
        {{ uuid }}
        <v-icon small right>mdi-content-copy</v-icon>
      </v-chip>
    </section>
    <section class="mb-2">
      <WordCount />
    </section>
    <section class="mb-6">
      <small>Domain</small>
      <v-select
        :items="domains"
        v-model="selectedDomain"
        return-object
        hide-details
        filled
        dense
        class="mb-2"
      ></v-select>

      <small>Language function</small>
      <v-select
        :items="languageFunctions"
        v-model="selectedLanguageFunction"
        return-object
        hide-details
        filled
        dense
      ></v-select>
    </section>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import WordCount from '@/components/item/WordCount'
import * as clipboardy from 'clipboardy'

export default {
  components: {
    WordCount,
  },
  computed: {
    ...mapState('item', [
      'uuid',
      'bucket',
      'level',
      'skill',
      'template',
      'updated',
      'status',
      'domain',
      'languageFunction',
    ]),
    ...mapState('todo', [
      'domains',
      'languageFunctions',
    ]),
    ...mapState('account', [
      'user',
    ]),
    userName() {
      return this.user.displayName
    },
    userInitials() {
      return this.user.displayName.split(' ').map(name => name[0]).join('')
    },
    selectedDomain: {
      get() { return this.domain },
      set(domain) { this.$store.commit('item/setDomain', domain) }
    },
    selectedLanguageFunction: {
      get() { return this.languageFunction },
      set(languageFunction) { this.$store.commit('item/setLanguageFunction', languageFunction) }
    },
  },
  methods: {
    async copyToClipboard(value) {
      await clipboardy.write(value);
      this.$store.dispatch('snackbar/snack', {
        mode: 'success',
        message: `✅ Copied to clipboard: <strong class="px-4">${value}</strong>`
      })
    },
  },
}
</script>