<template>
    <button
        v-bind:class="`es-btn ${type}`"
        @click="$emit('click', $event)"
        :disabled="disabled">
        <slot>Default Button</slot>
    </button>
</template>

<script>
export default {
    name: 'item-cms-btn',
    props: ['disabled', 'type']
}
</script>

<style scoped>
button {
    background-color: #3C1887;
    color: #EEE5FF;
    padding: 10pt;
    border-radius: 3pt;
    font-size: 1em;
    cursor: pointer;
    border: none;
}

button:hover {
    background-color: #2F1369;
}

button:active {
    background-color: #1D0C42;
}

.ghost {
    border: 1.4pt solid;
    background-color: unset;
    border-color: #23085A;
    color: #23085A;
}

.ghost:disabled {
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(230, 230, 230, 0.4);
    border: none;
}

.ghost:hover {
    background-color: #23085a20;
}

.ghost:active {
    background-color: #23085a45;
}
</style>
